<template>
  <div>
    <Preloader />
    <Navbar />
    <main>
      <slot></slot>
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
useHead({
  titleTemplate: "%s Empresa Nacional de Energía Eléctrica",
});
useSeoMeta({
  title: 'Empresa Nacional de Energía Eléctrica',
  ogTitle: 'Empresa Nacional de Energía Eléctrica',
  description: 'La Empresa Nacional de Energía Eléctrica, conocida como la ENEE, es un organismo autónomo responsable de la producción, transmisión, distribución y comercialización de energía eléctrica en Honduras.',
  ogDescription: 'La Empresa Nacional de Energía Eléctrica, conocida como la ENEE, es un organismo autónomo responsable de la producción, transmisión, distribución y comercialización de energía eléctrica en Honduras.',
  ogImage: 'https://firebasestorage.googleapis.com/v0/b/enee-hn.appspot.com/o/SEO-02.jpg?alt=media&token=f68e9892-ec93-4a0a-8f09-7e2f8995b6b0&_gl=1*1q9rn33*_ga*MTYyNDI5MzcwLjE2Nzg3Njg2MjA.*_ga_CW55HF8NVT*MTY5ODczMDk3My4yMjkuMS4xNjk4NzMxMDA4LjI1LjAuMA',
  twitterCard: 'summary_large_image',
})
</script>

<style lang="css">
main {
  min-height: 100vh;
}
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
