<template>
  <transition name="fade">
    <div v-if="show" class="loader" :style="`background-color: ${colorBG};`">
      <div class="loader" :style="`background-color: ${colorBG};`">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1721.1 381.5"
          style="enable-background: new 0 0 1721.1 381.5"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                class="st0"
                d="M740.2,89.7c4.7,0,7.2,2.1,7.2,6.7v28.7c0,4.5-2.5,6.7-7.2,6.7H604.1c-32.4,0-41.4,9.4-41.4,41.1v3h176
			c4.7,0,6.9,2.1,6.9,6.7v23.9c0,4.5-2.2,6.7-6.9,6.7h-176v5.1c0,31.7,9,41.1,41.4,41.1h136.1c4.7,0,7.2,2.1,7.2,6.7v28.7
			c0,4.5-2.5,6.7-7.2,6.7h-137c-62.9,0-87.2-21.8-87.2-79.8v-52c0-58.1,24.3-79.8,87.2-79.8H740.2z"
              />
              <path
                class="st0"
                d="M871.3,89.7c19.9,0,24.3,2.4,36.1,17.8l115.9,147.6c0.9,1.5,2.2,2.1,4,2.1h2.8c1.6,0,2.5-0.9,2.5-3V96.3
			c0-4.5,2.2-6.7,6.9-6.7h32.7c4.7,0,6.9,2.1,6.9,6.7v173.6c0,24.5-9,31.4-28,31.4h-26.8c-19,0-23.7-1.8-36.1-17.8L871.9,135.9
			c-1.2-1.5-2.2-2.1-4-2.1h-2.8c-1.9,0-2.5,0.9-2.5,3v157.8c0,4.5-2.2,6.7-6.9,6.7H823c-4.7,0-7.2-2.1-7.2-6.7V121.1
			c0-24.5,9-31.4,28-31.4H871.3z"
              />
              <path
                class="st0"
                d="M1374.9,89.7c4.7,0,7.2,2.1,7.2,6.7v28.7c0,4.5-2.5,6.7-7.2,6.7h-136.1c-32.4,0-41.4,9.4-41.4,41.1v3h176
			c4.7,0,6.9,2.1,6.9,6.7v23.9c0,4.5-2.2,6.7-6.9,6.7h-176v5.1c0,31.7,9,41.1,41.4,41.1h136.1c4.7,0,7.2,2.1,7.2,6.7v28.7
			c0,4.5-2.5,6.7-7.2,6.7h-137c-62.9,0-87.2-21.8-87.2-79.8v-52c0-58.1,24.3-79.8,87.2-79.8H1374.9z"
              />
              <path
                class="st0"
                d="M1672.3,89.7c4.7,0,7.2,2.1,7.2,6.7v28.7c0,4.5-2.5,6.7-7.2,6.7h-136.1c-32.4,0-41.4,9.4-41.4,41.1v3h176
			c4.7,0,6.9,2.1,6.9,6.7v23.9c0,4.5-2.2,6.7-6.9,6.7h-176v5.1c0,31.7,9,41.1,41.4,41.1h136.1c4.7,0,7.2,2.1,7.2,6.7v28.7
			c0,4.5-2.5,6.7-7.2,6.7h-137c-62.9,0-87.2-21.8-87.2-79.8v-52c0-58.1,24.3-79.8,87.2-79.8H1672.3z"
              />
            </g>
            <g>
              <path
                class="st1"
                d="M250.3,213.5c-14.4-1.4-28.7-2.7-42.9-4c-2.1-9.8-4.1-19.3-6.1-28.8c-0.5-2.5-1.3-5-1.7-7.6
			c-0.1-0.7,0.8-1.8,1.5-2.3c8.2-5.4,16.5-10.6,24.7-16c7.7-5,15.4-10.1,23.1-15.2c0.7-0.5,1.4-1.7,1.4-2.5c0.1-5,0-9.9,0.1-14.9
			c0.1-2.2-0.9-2.6-2.9-2.8c-8.9-0.8-17.8-1.7-26.7-2.5c-4.6-0.4-9.3-0.7-13.9-1.1c-6-0.5-12-1-17.9-1.6c-1.3-0.1-2.1-0.8-2.4-2.4
			c-1.8-9.1-3.8-18.2-5.8-27.2c-2-9.4-4-18.8-6-28.2c-1.4-6.7-3-13.4-4.3-20.1c-0.5-2.6-0.3-5.2-0.4-8c33.5,0,66.7,0,100.1,0
			c0,111.5,0,222.9,0,334.5c-10,0-19.9,0-29.9,0c-2.1-9.8-4.3-19.7-6.4-29.6c-1.8-8.3-3.5-16.7-5.3-25c-2.3-11-4.7-22.1-7-33.1
			c-1.6-7.4-3.2-14.9-4.7-22.3c-0.1-0.7,0.3-1.9,0.9-2.3c10.3-6.8,20.8-13.6,31.1-20.3c0.6-0.4,1.4-1.2,1.4-1.8
			C250.4,223.4,250.3,218.5,250.3,213.5z"
              />
              <path
                class="st2"
                d="M153.6,120.5c0,80.8,0,161.4,0,242.2c-33.4,0-66.8,0-100.7,0c0.3-1.3,0.4-2.7,0.9-3.8
			c8.6-17.1,16-34.6,22.4-52.6c5.9-16.5,11.9-33,17.3-49.7c6.2-19,11.9-38.1,17.4-57.3c5.5-19.3,10.3-38.8,15.7-58.1
			c1.5-5.2,3.7-10.2,7.6-14.3c4.2-4.4,9.6-5.7,15.2-6.3C150.8,120.4,152.1,120.5,153.6,120.5z"
              />
              <path
                class="st3"
                d="M287.3,28.2c33.5,0,66.7,0,100,0c0,41.7,0,83.2,0,125c-21.9-3.4-43.8-6.9-66.1-10.3
			c4-25.8,8.1-51.7,12.1-77.7c-8.5-1.3-17.1-2.7-25.8-4.1c-4,25.8-8.1,51.5-12.2,77.5c-2.3-0.2-4.5-0.3-6.6-0.7
			c-0.6-0.1-1.1-1-1.4-1.7c-0.2-0.4,0-1,0-1.5c0-34.6,0-69.1,0-103.7C287.3,30.1,287.3,29.2,287.3,28.2z"
              />
              <path
                class="st3"
                d="M387.2,362.8c-33.3,0-66.4,0-100.1,0c0.2-2.8,0.3-5.6,0.7-8.3c1.1-7.5,2.3-14.9,3.5-22.4c1.3-8,2.7-16,4-24.1
			c1-6.1,1.7-12.3,2.7-18.4c1.1-7,2.4-14.1,3.5-21.1c0.9-5.9,1.8-11.8,2.7-17.7c0.7-4.8,1.4-9.6,2.1-14.4c0.1-0.3,0.5-0.6,0.8-1.1
			c2.1,1.9,4.1,3.7,6.2,5.4c8.9,7.3,17.6,15,26.8,21.8c9.4,7,19.3,13.3,29.2,19.7c5.3,3.4,11,6.1,16.4,9.2c0.7,0.4,1.5,1.6,1.5,2.4
			c0.1,22.3,0.1,44.7,0.1,67C387.4,361.5,387.3,362.2,387.2,362.8z"
              />
              <path
                class="st2"
                d="M153.6,108c-6.4,0.5-12.5,1.4-18,4.7c-6.2,3.7-9.7,9.6-12.7,15.9c-0.3,0.7-1.4,1.5-2,1.5
			c-2.9-0.2-6-0.3-8.7-1.3c-10.7-3.7-21.5-7.4-32-11.6c-9.1-3.6-18-7.8-27-11.7c0-25.8,0-51.5,0-77.3c33.4,0,66.8,0,100.4,0
			C153.6,54.8,153.6,81.3,153.6,108z"
              />
              <path
                class="st3"
                d="M315.7,178.6c23.9,3.7,47.6,7.5,71.5,11.2c0,21,0,42,0,63.3c-2.8-1.5-5.6-2.7-8.2-4.3
			c-9.2-5.7-18.4-11.3-27.3-17.4c-7.9-5.4-15.4-11.4-22.9-17.3c-4.3-3.3-8.2-7-12.5-10.3c-3.5-2.6-4.4-5.7-3.4-9.9
			c1-4.2,1.4-8.4,2.1-12.6C315.2,180.3,315.5,179.5,315.7,178.6z"
              />
              <path
                class="st3"
                d="M387.4,287.5c-8.7-5.2-16.8-9.8-24.7-14.9c-7.9-5.1-15.7-10.6-23.3-16.2c-6.1-4.5-11.8-9.5-17.6-14.4
			c-4.1-3.5-8.1-7.2-12.3-10.6c-1.5-1.2-2-2.7-1.7-4.5c1-6.7,2.1-13.4,3.1-20.1c0-0.3,0.3-0.6,0.6-1.1c2.3,1.9,4.4,3.8,6.6,5.5
			c10.3,8.1,20.5,16.4,31,24.2c11.2,8.2,23.1,15.4,35.6,21.7c1.9,1,2.7,2.2,2.7,4.5C387.3,270,387.4,278.3,387.4,287.5z"
              />
              <path
                class="st2"
                d="M95.8,164.7c-5.3-2.6-10.2-2.5-15-1c-6.7,2-12.6,5.2-17,11c-4.5,5.9-5.9,12.1-3.3,19c2,5.2,0.2,9.5-2.3,13.8
			c-0.4,0.7-1.7,0.9-2.5,1.3c-0.5-0.9-1.5-2-1.3-2.8c1.7-6.9,0.2-13.6-1.1-20.4c-1.2-6.2,1.7-11.5,5.6-16.1
			c5.6-6.6,13.1-9.1,21.8-9.5c-7.5-3.3-14.3-1.3-21.4,1.9c2.1-7.5,4.2-14.6,6.2-21.9c16.2,6.4,32.1,12.5,49.5,15.8
			c-1.3,4.4-2.6,8.7-3.8,13c-2.8-0.5-5.2-1.3-7.7-1.4c-7.3-0.2-13.7,2.7-19.6,6.9c-5.1,3.7-8.4,8.6-9.4,14.7
			c-0.6,3.6,0.4,7.5,0.9,11.2c0.6,3.8,0,7.2-2.8,10c-2,2-3.8,4.1-5.7,6.2c-2.2,2.4-2.4,5.2-1.7,8.2c0.6,2.4,0.8,4.8,1.2,7.2
			c0.7,4.2-1.3,7.5-4.1,10.1c-4.9,4.7-5.1,10.3-4.3,16.4c0.4,3.3-0.1,6.5-2,9.3c-0.4,0.6-1.4,0.8-2.1,1.3c-0.3-0.7-0.8-1.4-0.8-2.2
			c-0.1-7.5-0.2-15.1,0.1-22.6c0.1-2.2,0.9-4.7,2.3-6.4c3.3-4,3.7-8.3,2.1-12.9c-2-5.8-0.5-10.5,4-14.5c1.6-1.4,3-3,4.3-4.7
			c2.3-3.2,1.9-6.9,0.9-10.2c-3.3-10.7,3.7-21.6,12.3-26.2C84.2,166.5,89.3,164.3,95.8,164.7z"
              />
              <path
                class="st2"
                d="M110.6,169.3c-0.1,2.1-0.2,3.6-2.5,4.4c-5.8,2-10.9,5.1-14.6,10.3c-3.7,5.4-5.5,11-3.7,17.5
			c0.5,1.8,0.8,3.8,0.8,5.7c0,2.8-0.9,5.4-3.1,7.5c-1.9,1.7-3.7,3.5-5.4,5.5c-3.6,4.1-1.8,8.7-1,13.2c0.7,4.2,0.8,8.2-3,11.4
			c-2,1.7-3.6,3.8-5.3,5.8c-2.1,2.3-1.9,5.2-1.4,7.9c0.3,2,1,3.9,1.3,5.9c0.5,3.9-0.5,7.3-3.6,10c-1.9,1.6-3.6,3.4-5.1,5.4
			c-2.7,3.6-1.9,7.7-0.8,11.5c1.8,6.1,0.3,10.9-5,14.5c-0.7,0.5-1.2,1.3-1.9,1.8c-0.6,0.4-1.4,0.9-1.9,0.7c-0.6-0.2-1.2-1.2-1.2-1.7
			c0.2-2.4,0.3-4.9,1.2-7.1c1.3-3.3,1.6-6.5,0.8-9.8c-0.5-1.9-0.9-3.8-1-5.7c-0.2-2.9,0.6-5.5,2.7-7.6c1.7-1.8,3.5-3.5,5.3-5.3
			c3.8-3.9,3-8.4,1.7-13c-1.3-4.9-0.9-9.2,3.4-12.7c1.5-1.2,2.9-2.7,4.2-4.2c2.8-3.4,2.7-7.2,1.6-11.2c-0.5-1.7-0.6-3.5-0.8-5.2
			c-0.4-3.4,0.8-6.1,3.4-8.3c1-0.8,1.8-1.8,2.7-2.7c3.8-3.4,5.4-7.6,4-12.4c-2.5-8.7-0.5-16.4,5.6-22.6
			C93.9,172.5,101.5,169.1,110.6,169.3z"
              />
              <path
                class="st3"
                d="M387.4,176.9c-23.4-3.7-46.4-7.3-69.9-11c0.6-3.4,1.1-6.9,1.8-10.6c9.5,1.6,18.8,3.2,28.2,4.6
			c8.1,1.3,16.3,2.4,24.4,3.6c4.6,0.7,9.3,1.3,13.9,2.1c0.6,0.1,1.6,1.1,1.6,1.8C387.5,170.6,387.4,173.7,387.4,176.9z"
              />
              <path
                class="st1"
                d="M211.5,254.3c7.8,36.3,15.4,72.3,23.2,108.4c-2.6,0-4.6,0-6.8,0c-2.5-11.6-4.9-23.2-7.4-34.8
			c-1.9-9.1-4-18.1-5.9-27.2c-2.3-11-4.6-21.9-6.9-32.9c-0.6-2.6-0.9-5.3-1.7-7.8c-0.5-1.6,0-2.2,1.1-2.9
			C208.5,256.2,209.9,255.3,211.5,254.3z"
              />
              <path
                class="st2"
                d="M107.8,179.9c-6.2,20-12.4,40-18.6,60.1c-0.2,0-0.3-0.1-0.5-0.1c-0.6-3.5-1.2-6.9-1.7-10.4
			c-0.5-3.7,1.2-6.5,3.6-9c1-1.1,2-2.2,3.1-3.1c4-3.2,4.5-7.5,3.3-12c-2.3-9,0.2-16.6,6.6-23c1-1,2.3-1.9,3.5-2.8
			C107.3,179.6,107.6,179.7,107.8,179.9z"
              />
              <path
                class="st1"
                d="M216.9,154.1c-0.5-2.4-1.1-4.6-1.4-6.8c-0.1-0.4,0.7-1,1.3-1.3c8.1-5.3,16.3-10.6,24.4-15.8
			c1-0.7,2-1.4,3.5-2.3c0,2.8,0.1,5.2-0.1,7.5c0,0.6-0.7,1.3-1.2,1.6C234.7,142.6,226.1,148.2,216.9,154.1z"
              />
              <path
                class="st1"
                d="M201.8,209c-2.6-0.3-4.6-0.6-6.9-0.8c-1.2-5.5-2.4-11.2-3.6-16.8c-0.8-3.8-1.7-7.5-2.5-11.2
			c-0.1-0.6,0.1-1.5,0.5-1.8c1.6-1.1,3.4-2,5.3-3.2C197,186.5,199.3,197.5,201.8,209z"
              />
              <path
                class="st1"
                d="M210.5,150.1c0.3,1.8,0.3,3.2,0.8,4.4c1,2.4-0.1,3.5-2,4.6c-3.7,2.2-7.4,4.6-11.3,7.1c-0.1-0.8,0-1.3-0.1-1.7
			c-2.3-4.6,0.1-7.1,3.9-9.2C204.8,153.9,207.5,152,210.5,150.1z"
              />
              <path
                class="st1"
                d="M215.1,245.6c-0.3-1.8-0.4-3.1-0.8-4.2c-1-2.3-0.3-3.6,1.8-4.7c3.7-2.2,7.3-4.6,11.3-7.2
			c0.5,2.3,1,4.1,1.4,5.9c0.1,0.5-0.2,1.4-0.6,1.7C223.9,239.9,219.6,242.6,215.1,245.6z"
              />
              <path
                class="st1"
                d="M244.6,218.2c0,2.9,0,5.2,0,7.5c0,0.4-0.4,1-0.8,1.3c-2.8,1.9-5.6,3.7-8.4,5.5c-1.1,0.7-1.9,0.6-2-0.9
			c0-0.1,0-0.2,0-0.3c-1.9-4.1-0.2-6.4,3.6-8.2C239.6,221.9,241.8,220,244.6,218.2z"
              />
              <path
                class="st2"
                d="M87.7,244.8c-2.5,7.6-5,15.1-7.5,22.8c-3.9-6.7-2.2-14.9,3.7-19.6c0.6-0.5,1.1-1.3,1.6-1.9
			c0.5-0.7,1-1.4,1.4-2.1C87.2,244.3,87.4,244.6,87.7,244.8z"
              />
              <path
                class="st1"
                d="M208.6,241.5c0.6,2.3,1.2,4.4,1.6,6.4c0.1,0.4-0.2,1-0.6,1.3c-1.6,1.1-3.4,2.1-5.1,3.2
			C202.6,245.6,202.6,245.6,208.6,241.5z"
              />
              <path
                class="st1"
                d="M187.4,173.1c-1.6-8.2-1.6-8.2,4.4-10.5c0.5,2.3,1,4.5,1.5,6.9C191.5,170.6,189.6,171.8,187.4,173.1z"
              />
            </g>
          </g>
        </svg>

        <div class="disclamer">
          <img src="/logoGob.svg" class="ma-1" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Lottie from "./Lottie";

export default {
  name: "Preloader",
  components: {
    // lottie: Lottie,
  },
  data() {
    return {
      colorBG: "#fff",
      color: "",
      isMenu: true,
      anim: null,
      show: true,
      show_lottie: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show_lottie = true;
        this.showLoader();
      }, 500);
    });
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
        this.show = false;
      }, 3000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import "@/assets/scss/_responsive";

.st0 {
  fill: #fff;
}

.st1 {
  fill: none;
  stroke: #f69224;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: naranjas_stroke 2s ease-in forwards;
}

.st2 {
  // fill: #59c6d5;
  fill: none;
  stroke: #59c6d5;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: azul_stroke 2s ease-in forwards;
}

.st3 {
  // fill: #ea2228;

  fill: none;
  stroke: #ea2228;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: red_stroke 2s ease-in forwards;
}

.logo-svg {
  width: 200px;
  height: 200px;
}

.loader {
  width: 100vw;
  height: 100vh;
  z-index: 1000 !important;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;

  svg {
    width: 400px;

    @include responsive(mobile) {
      width: 250px;
    }
  }

  img {
    width: 400px;

    @include responsive(mobile) {
      width: 300px;
    }
  }

  .lottie-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}

@include responsive(mobile) {
  .lottie-content {
    width: 75% !important;
  }
}

@include responsive(desktop) {
  .lottie-content {
    width: 250px !important;
    // height: 100px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.by-ocho {
  position: absolute;
  bottom: 15vh;
}

.st0 {
  fill: none;
  stroke: #000000d9;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: circle_stroke 2s ease-in forwards;
}

@keyframes circle_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #000;
  }
}

@keyframes red_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #ea2228;
  }
}

@keyframes azul_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #59c6d5;
  }
}

@keyframes naranjas_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #f69224;
  }
}

.disclamer {
  position: absolute;
  bottom: 10vh;

  img {
    width: 150px;
  }
}
</style>
