<template>
  <div class="wrap-navbar">
    <div class="live-vive" v-if="setting?.liveActions.active">
      <v-container class="pt-0 mt-0 container-live">
        <div class="circle"></div>
        <b>{{ setting.liveActions.title }}</b> {{ setting.liveActions.text }}
      </v-container>
    </div>
    <div class="wrap-nav">
      <v-container class="pt-1 px-1 pb-1">
        <div class="wrap-logo-nav">
          <NuxtImg src="/logoGob.svg" height="40px" class="d-xs-none" alt="Gob" />
          <NuxtLink to="/">
            <NuxtImg src="/logoGobResponsive.svg" class="d-xs ml-2" height="26px" alt="Gob" />
          </NuxtLink>
          <NuxtLink to="/">
            <NuxtImg src="/logo-01.svg" height="38px" alt="Logo" class="logo-enee" />
          </NuxtLink>
          <div class="wrap-nav-list">


            <NuxtLink to="/">Inicio</NuxtLink>
            <NuxtLink to="/sobre-nosotros">Sobre Nosotros</NuxtLink>
            <NuxtLink to="/noticias">Noticias</NuxtLink>
            <NuxtLink to="/control-interno">Control Interno</NuxtLink>

          </div>
          <v-btn :icon="menuOpen ? 'mdi-close' : 'mdi-menu'" class="menu-btn" size="small" elevation="0"
            style="z-index: 1000" @click="menuOpen = !menuOpen">
          </v-btn>

        </div>
      </v-container>
    </div>
    <div class="wrap-link-dinamic">
      <v-container class="wrap-dinamic pa-1">
        <div class="wrap-btn-links">

          <v-btn v-if="setting?.btnCTA.active" elevation="0" :color="setting.btnCTA.color" link
            :href="setting.btnCTA.redirect" class="my-1 btn-cta btn-enee" target="_blank" dark rounded="lg"
            style="color: #fff">{{ setting.btnCTA.text }} </v-btn>
          <div :class="{ 'wrap-btn-ul': true, 'wrap-CTA-active': setting?.btnCTA.active }">
            <ul class="ul-style">

              <li v-for="(link, index) in linkSort" :key="index">
                <a :href="link.link" v-if="!link.isCluster" target="_blank">
                  <img src="/icono-yo-defiendo-mi-patrimonioBlanco.svg" class="logo-icon-energia"
                    v-if="link.type == 'ahorro'" />
                  {{
      link.name
    }}</a>
                <v-menu v-else>
                  <template v-slot:activator="{ props }">
                    <a v-bind="props">{{ link.titleCluster }}</a>
                  </template>
                  <v-list nav dense color="primary">
                    <v-list-item v-for="(item, index) in link.cluster" :key="index" :value="index" link target="_blank"
                      :href="item.link">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>

            </ul>
            <!-- icon="mdi-magnify" -->
            <v-btn icon="mdi-map" size="small" elevation="0" @click="dialogSearch = !dialogSearch"></v-btn>
          </div>
        </div>
      </v-container>
    </div>

    <v-dialog v-model="dialogSearch" v-if="dialogSearch" width="500px">
      <!-- <v_Search /> -->
      <v-card>
        <NuxtImg src="/organi-01.svg"></NuxtImg>
      </v-card>
    </v-dialog>
    <Transition name="slide-fade">
      <div class="menu-wrap" v-show="menuOpen">
        <v-container class="h-100 grid align-center">
          <NuxtImg src="/logo-01.svg" height="40px" alt="Logo" draggable="false" />
          <v-row>
            <v-col cols="12" md="8" sm="6" class="d-sm-block">
              <v-list class="grid col-2">
                <span v-for="(link, index) in linksSort" :key="index" class="text-decoration-underline lnk-nuxt px-1">
                  <NuxtLink class="text-decoration-underline lnk-nuxt px-1" @click="menuOpen = !menuOpen"
                    v-if="link.isDynamic" :href="`/page/${link.code}`">{{ link.name }}
                  </NuxtLink>
                  <NuxtLink class="text-decoration-underline lnk-nuxt px-1" @click="menuOpen = !menuOpen"
                    v-else-if="link.isDynamic == false" :href="`/${link.code}`">{{ link.name }}</NuxtLink>
                </span>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" sm="6" class="d-flex flex-column align-start">
              <p class="font-weight-light font-small">

                El Gobierno Socialista Democrático de la presidenta Xiomara Castro, a través de la Ley Especial de
                Energía, decretó como patrimonio del pueblo hondureño a la Empresa Nacional de Energía Eléctrica. La
                administración del Dr. Erick Tejada avanza en el rescate de tu empresa.
              </p>
              <div class="justify-sm-end justify-center d-flex my-3 align-center align-sm-start gap">
                <v-btn link href="https://www.facebook.com/ENEEHonduras/" size="x-large" target="_blank" variant="plain"
                  icon="mdi-facebook">
                </v-btn>
                <v-btn link href="https://www.instagram.com/eneehonduras/" size="x-large" target="_blank"
                  variant="plain" icon="mdi-instagram">
                </v-btn>
                <v-btn link href="https://www.youtube.com/@lanuevaenee" size="x-large" target="_blank" variant="plain"
                  icon="mdi-youtube">
                </v-btn>
              </div>
              <p class="font-weight-light font-small">
                <span class="font-weight-bold">Contáctanos</span> <br>

                <a class="link-blank" href="mailto:atencionalcliente@enee.hn"> <v-icon>mdi-email</v-icon>
                  atencionalcliente@enee.hn </a> <br><br>


                <span class="font-weight-bold"> <v-icon size="15">mdi-phone</v-icon> Línea fija:</span>
              <ul style=" list-style-type: circle;" class="ml-4">
                <li> Centro Sur 2232-8000 / 2232-8100 </li>
                <li>Noroccidente: 2556-6402 / 2556-7272</li>
                <li>Litoral Atlántico 2441-2635 y 2441-2423</li>

              </ul>
              </p>
            </v-col>
          </v-row>
          <NuxtImg src="/logoGob.svg" height="50px" class="d-xs-none" alt="Gob" draggable="false" />
        </v-container>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import v_Search from "./search.vue";
import { onMounted, ref } from "vue";
import { collection, doc, query, where } from "firebase/firestore";
import { useDocument, useFirestore } from "vuefire";

const db = useFirestore();
var setting = ref();

const config = useRuntimeConfig();

const ambiente = ref(config.public.ambiente);

setting = useDocument(doc(db, "settings", "navbar"));
const PagesCollectionRef = collection(db, "pages");
const pagesLink = useCollection(
  query(
    PagesCollectionRef,
    where("active", "==", true),
    where("showMenu", "==", true)
  )
);

// const todosRef = collection(db, 'todos')
// const todos = useCollection(todosRef)

const linksSort = computed(() => {
  function compare(a, b) {
    if (a.position < b.position) return -1;
    if (a.position > b.position) return 1;
    return 0;
  }
  return pagesLink.value.sort(compare);
});

//---------traer listado de data ------
//     const todosRef = collection(db, 'todos')
//     const todos = useCollection(todosRef)
// documentacion
// https://vuefire.vuejs.org/guide/realtime-data.html

const menuOpen = ref(false);
const dialogSearch = ref(false);

const linkSort = computed(() => {
  function compare(a, b) {
    if (a.position < b.position) return -1;
    if (a.position > b.position) return 1;
    return 0;
  }
  return setting.value?.links.sort(compare);

});

onMounted(() => {
  document.documentElement.setAttribute("data-scroll", "0");
  document.addEventListener("scroll", function (e) {
    var html = document.documentElement.setAttribute(
      "data-scroll",
      document.documentElement.scrollTop
    );
  });
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_responsive";

.wrap-navbar {
  height: auto;
  background-color: #fff;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;

  .live-vive {
    height: 15px;
    background-color: red;
    width: 100%;

    margin: 0px;

    .container-live {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
      font-size: 9px;

      .circle {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        background-color: #ffcc00;
        margin-right: 10px;
      }
    }
  }
}

.wrap-logo-nav {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo-enee {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.wrap-link-dinamic {
  background-color: #59c6d5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-nav-list {
  // -webkit-mask: linear-gradient(
  //   90deg,
  //   transparent 1%,
  //   #000 2%,
  //   #000 90%,
  //   transparent 100%
  // );
  // mask: linear-gradient(
  //   90deg,
  //   transparent 1%,
  //   #000 2%,
  //   #000 90%,
  //   transparent 100%
  // );
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: none;
  position: absolute;
  right: 50px;

  @include responsive(mobile) {
    display: none;
  }

  @include responsive(small) {
    display: none;
  }

  .router-link-active,
  .router-link-exact-active {
    color: #59c6d5;
  }

  a {
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.8em;
    color: #000;

    &:hover {
      color: #59c6d5;
    }
  }
}

.logo-icon-energia {
  width: 0.8em;
  height: 0.8em;
}

html[data-scroll="0"] {
  .wrap-link-dinamic {
    height: 50px;
    transition: 0.3s ease-in-out;

    @include responsive(mobile) {
      height: initial;
    }
  }

  .wrap-nav {
    box-shadow: none;
  }
}

.wrap-nav {
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
  height: 64px;
  // box-shadow: 0px 5px 10px #8181813c;
}

.wrap-link-dinamic {
  height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;

  a {
    font-size: 0.8em;
  }
}

.wrap-dinamic {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .wrap-btn-links {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include responsive(mobile) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.wrap-CTA-active {
  width: calc(100% - 220px) !important;
}

.wrap-btn-ul {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include responsive(mobile) {
    width: calc(100% - 10px) !important;
  }

  .ul-style {
    -webkit-mask: linear-gradient(90deg,
        transparent 1%,
        #000 2%,
        #000 90%,
        transparent 100%);
    mask: linear-gradient(90deg,
        transparent 1%,
        #000 2%,
        #000 90%,
        transparent 100%);
    display: flex;
    flex: 1 1 auto;
    gap: 10px;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      margin: 0;
      padding: 0;
      scroll-snap-align: start;
      display: list-item;
      text-align: -webkit-match-parent;

      a {
        color: #fff;
        margin-right: 10px;
        text-decoration: none;
        white-space: nowrap;
        transition: 0.2s ease;

        &:hover {
          text-decoration: underline;
        }

        &:after {
          background-image: url("/arrow.svg");
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: 100%;
          content: "";
          display: inline-block;
          height: 0.5em;
          margin-left: 0.4em;
          transition: 0.2s ease;
          vertical-align: middle;
          width: 0.5em;
        }
      }
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100vw);
}

.menu-wrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;

  background-color: #fff;
  z-index: 999;
  top: 0;
  left: 0;
}

.btn-cta {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive(mobile) {
    // display: none;?
    height: 30px;
    font-size: 10px;
  }
}

.d-xs {
  display: none;

  @include responsive(mobile) {
    display: block;
  }
}

.d-xs-none {
  display: block;

  @include responsive(mobile) {
    display: none;
  }
}

.menu-btn {
  background-color: #fefefe !important;
  box-shadow: none;

  // @include responsive(mobile) {
  //   position: fixed;
  //   bottom: 20px;
  //   right: 20px;
  //   border: #59c6d5 solid 1px;
  //   box-shadow: 0px 10px 20px #055d6a85 !important;
  // }
}

.grid {
  display: grid;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.font-small {
  font-size: 13px;
}

.lnk-nuxt {
  color: #000;
  margin-bottom: 20px;
}

.link-blank {
  color: #151515;
  margin-right: 10px;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.2s ease;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    background-image: url("/arrowblack.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: inline-block;
    height: 0.5em;
    margin-left: 0.4em;
    transition: 0.2s ease;
    vertical-align: middle;
    width: 0.5em;
  }
}

ul {
  margin-left: 20px;
  margin-top: 10px;
  list-style: square url("/arrowcopy.svg");
  //  list-style-image: url('/arrowxopy.svg')
}
</style>
