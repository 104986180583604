<template>
  <v-divider></v-divider>
  <v-layout row wrap class="px-0 bg-footer">
    <v-container class="py-13">
      <v-footer class="bg-footer px-0" style="color: #fff;" height="auto">
        <v-row>
          <v-col cols="12" md="2" sm="6" lg="2" class="d-none d-sm-block font-small">
            <ul class="menu-footer">
              <div v-for="(link, index) in linksSort" :key="index">
                <li class="text-caption" v-if="link.isDynamic">
                  <NuxtLink class="text-decoration-underline lnk-nuxt px-1" :href="`/page/${link.code}`">{{ link.name }}
                  </NuxtLink>

                </li>
                <li class="text-caption" v-else-if="!link.isDynamic">
                  <NuxtLink class="text-decoration-underline lnk-nuxt px-1" :href="`/${link.code}`">{{ link.name }}
                  </NuxtLink>

                </li>
              </div>


            </ul>
          </v-col>

          <v-col cols="12" md="4" sm="6" lg="4" class="d-flex">
            <p class="font-weight-light font-small text-caption">
              <span class="font-weight-bold">Contáctanos</span> <br>
            <ul>
              <li>
                <a class="link-blank" href="tel:118" target="_blank"> <v-icon size="15">mdi-phone</v-icon>Línea Gratuita
                  Desde Celular 118</a>
              </li>
              <li>
                <a class="link-blank" href="tel:+50422764370" target="_blank"> <v-icon size="15">mdi-phone</v-icon>Línea
                  Telefónica 2276-4370</a>
              </li>
              <li>
                <a class="link-blank" href="https://twitter.com/EneeHnOficial" target="_blank"> <v-icon
                    size="15">mdi-twitter</v-icon>@EneeHnOficial</a> <br><br>
              </li>
            </ul>


            <span class="font-weight-bold"> <v-icon size="15">mdi-phone</v-icon> Línea fija:</span>
            <ul style=" list-style-type: circle;" class="ml-4">
              <li>Centro Sur 2232-8000 / 2232-8100 </li>
              <li>Noroccidente: 2556-6402 / 2556-7272</li>
              <li>Litoral Atlántico 2441-2635 y 2441-2423</li>

            </ul>
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="6" lg="4">

            <span class="font-weight-bold"><v-icon size="20">mdi-map-marker</v-icon> Ubicación</span>
            <ul>
              <li>
                <p class="text-caption font-weight-light"><span class="font-weight-bold">Oficinas principales:</span>
                  Centro Cívico Gubernamental, Cuerpo Bajo C, edificio Tolupán. Tegucigalpa, Francisco
                  Morazán Honduras.
                </p>
              </li>
              <li>
                <p class="text-caption font-weight-light"><span class="font-weight-bold">Noroccidente:</span> Plantel La
                  Puerta, bulevar del Sur, 33 calle. San Pedro Sula, Cortés, Honduras.
                </p>
              </li>
              <li>
                <p class="text-caption font-weight-light"><span class="font-weight-bold">Litoral Atlántico:</span>
                  Colonia Sutrasfco, contiguo a la terminal de buses San José. La Ceiba, Atlántida,
                  Honduras.
                </p>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="2" sm="6" lg="2" class="">
            <div class="wrap-logo-redes">

              <div class="btn-footer-redes mb-6 ">
                <v-btn link href="https://www.facebook.com/ENEEHonduras/" density="comfortable" target="_blank"
                  icon="mdi-facebook">
                </v-btn>
                <v-btn link href="https://www.instagram.com/eneehonduras/" density="comfortable" target="_blank"
                  icon="mdi-instagram">
                </v-btn>
                <v-btn link href="https://www.youtube.com/@lanuevaenee" density="comfortable" target="_blank"
                  icon="mdi-youtube">
                </v-btn>
              </div>


              <NuxtImg src="/logo-dark.svg" class="logo-enee-dark" width="100%" />
            </div>

          </v-col>
        </v-row>
      </v-footer>
    </v-container>
  </v-layout>
  <div class="text-center py-4 font-bold px-0" style="background-color: #1a1718; color: #fff;">
    <span class="text-caption"> Copyright enee.hn &copy; {{ currentYear }}</span>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { collection, doc, query, where } from "firebase/firestore";
import { useDocument, useFirestore } from "vuefire";
const db = useFirestore();
const currentYear = new Date().getFullYear();
const PagesCollectionRef = collection(db, 'pages');
const pagesLink = useCollection(
  query(
    PagesCollectionRef,
    where("active", "==", true),
    where("showMenu", "==", true),
  )
);

const config = useRuntimeConfig()

const ambiente = ref(config.public.ambiente)
// console.log(ambiente.value)
// console.log(config.public.ambiente)


// const todosRef = collection(db, 'todos')
// const todos = useCollection(todosRef)


const linksSort = computed(() => {
  function compare(a, b) {
    if (a.position < b.position) return -1;
    if (a.position > b.position) return 1;
    return 0;
  }
  return pagesLink.value.sort(compare);
});


</script>

<style scoped lang="scss">
@import "@/assets/scss/responsive";

.bg-footer {
  background-color: #231f20;
}

.flex {
  display: flex;
  gap: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 35%);
}

.gap {
  gap: 5px;
}

.logo-enee-dark {
  width: 100%;

  @include responsive(mobile) {
    width: 200px;
  }
}

.gap-2 {
  @include responsive(mobile) {
    gap: 14px;
  }

  gap: 0;
}

.description {
  @include responsive(mobile) {
    font-size: 14px;
    width: 70%;
  }
}

.lnk-nuxt {
  color: #ffffff;
  margin-bottom: 0px;
  font-weight: 200;
}

.link-blank {
  color: #ffffff;
  margin-right: 10px;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.2s ease;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    background-image: url("/arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: inline-block;
    height: 0.5em;
    margin-left: 0.4em;
    transition: 0.2s ease;
    vertical-align: middle;
    width: 0.5em;
  }
}

ul {
  margin-left: 20px;
  margin-top: 10px;
  list-style: square url("/arrowcopy.svg");
  //  list-style-image: url('/arrowxopy.svg')
}

.btn-footer-redes {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include responsive(mobile) {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
  }
}

.d-flex {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @include responsive(mobile) {
    align-items: flex-start;
  }
}

.menu-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.wrap-logo-redes {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @include responsive(mobile) {}
}
</style>
